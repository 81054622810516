import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648066155/confinamiento1_kalp8x.png",
        "alt": "Ximena JPG"
      }}></img></p>
    <h3>{`Guille Acosta es parte del Taller Maestro Eusebio Rubalcaba; sus palabras son sencillas, libres, nostálgicas y con un sentido literario tan etéreo que de párrafo en párrafo te engancha hasta la última imagen de su narración y reflexión pandémica.`}</h3>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote"><em parentName="p">{`Quien controla el miedo de la gente,`}</em>{`
`}<em parentName="p">{`se convierte en el amo de sus almas.`}</em>{`
`}<em parentName="p">{`Maquiavelo`}</em></p>
      </blockquote>
    </blockquote>
    <p>{`El virus apareció en mi vida como en un caleidoscopio de imágenes que nunca olvidaré, mi hermano menor, un hombre fuerte recién entrado en los cincuenta, sentado frente a mí en la cocina de su casa, mirándome muy serio mientras pronunciaba bajito:`}</p>
    <p><strong parentName="p">{`—¿Y sí nos morimos? —`}</strong>{` en sus ojos parecía haber vergüenza por haberse atrevido a soñar un día con vivir una larga vida.`}</p>
    <p>{`Aún no llegaba la primavera del 2020 y estábamos a unos días de anunciarse el estado de confinamiento. Mi hermana, con el pavor dibujado en el rostro, rociaba antiséptico cada cinco minutos por toda su cerrada casa, no fuera a ser que el bicho se le colara por una de las rendijas y la asaltara en medio de la noche en la quietud de su hogar.`}</p>
    <p>{`La sentencia firme de mi sobrino que, enfundado en short y corbata, escapaba del nuevo `}<em parentName="p">{`home office`}</em>{` para afirmar con aire grave y lejano de su natural alegría, que iniciábamos la era de un nuevo orden mundial. Las decenas de llamadas de parientes y amigos diciéndonos “hasta luego” porque `}<strong parentName="p">{`sentíamos que esto pasaría pronto y pronto nos volveríamos a ver;`}</strong>{` sin imaginar que la tecnología nos tenía preparadas nuevas formas de estar juntos.`}</p>
    <p>{`La ausencia de las prostitutas en Tlalpan, la calle donde vivo en la Ciudad de México, que de un día a otro dejaron de ser parte del paisaje urbano, y la mirada muda de mi esposo en la que me perdí para esconder un nuevo miedo y una nueva incertidumbre a los que, sin remedio, habríamos de enfrentarnos en los siguientes días, sin vislumbrar ni por asomo su fin.`}</p>
    <p><strong parentName="p">{`La incredulidad me tomó de la mano, me sentí la marioneta de unos dioses malignos que me habían exiliado,`}</strong>{` sin anuncio alguno, de un escenario en el que yo ejecutaba con maestría la función. Y ahora ahí estaba yo: arrumbada, despatarrada junto a un montón de títeres más, todos sin guión, sin escenario y confinados en un baúl donde el silencio y la oscuridad reinaban.`}</p>
    <p>{`Al menos por unas noches, `}<strong parentName="p">{`me quitaría el sueño un sentimiento de fragilidad ante la vida y la muerte;`}</strong>{` me invadía también un delirante temor de sentirme atrapada, como si estuviésemos en medio de una gran guerra y mi país estuviese sitiado y yo sin posibilidad alguna de evacuación, porque la única ruta de escape conocido era la muerte. Y no sería sino hasta pasados los días que la confusión y el miedo parecieron darle la mano al encierro y a la monotonía diaria del “Quédate en Casa”, etapa en la que lo único que cambiaba era la curva de infectados y muertos por el bicho, ascendiendo lentamente hacia lo desconocido. Era este un abierto desafío de sobrevivencia y la primavera de abril recién comenzaba.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648066151/confinamiento3_rvzeo6.png",
        "alt": "Ximena JPG"
      }}></img></p>
    <p>{`Una mañana dejó de ser igual a las demás cuando `}<strong parentName="p">{`un pequeño colibrí entró a mi habitación`}</strong>{` y se posó por un segundo sobre el pistilo amarillo de una gran flor roja de anturio que parecía seguir al sol asomándose por la ventana de mi habitación; en medio de la quietud en la que me encontraba mirando videos, el aleteo del ave pareció detener el tiempo y mostrarme la eternidad de un instante, de un instante feliz.`}</p>
    <p>{`Más tarde (nunca sabré en qué momento habrá sido, porque de repente el tiempo parecía detenerse en medio del tedio uniforme de los días de confinamiento), mientras cocinaba frente a la estufa, una pizpireta abeja pasó volando frente a mis narices y fue directamente a empinarse al grifo de agua que estaba a mí lado; asustada salí despacio con el zumbido aún en mis oídos, y me quedé muy quieta en la puerta de la cocina esperando que ella se marchara… Ella se fue y yo volví a lo mío. Entonces la abejita comenzó a jugar conmigo: ella volvía a entrar y yo volvía a salir y así largo tiempo en un ir y venir sin fin. Hasta que, pasado un rato, recargada en el umbral de la cocina, observé a mi amiguita beber y noté que sus nalgas habían crecido… La educada cautela del panal para entrar a beber agua me hizo reír a carcajadas. Comencé a entender la importancia de las pequeñas cosas en esta inesperada etapa de mi vida y me sentí feliz. Así que apenas vi brillar la chispa de mi entusiasmo, intuí que una de las armas para enfrentar esta guerra contra el bicho, era la alegría y me aferré a sentirla como un náufrago se aferra a un pedazo de madera en medio del agreste mar. Los dioses siniestros que habían ideado este confinamiento como la principal bandera para hacerle frente al virus, ya podían irse preparando para el contraataque.`}</p>
    <p>{`Y mientras tanto en el mundo, como si ésta fuese una guerra multidimensional, brotaban por todas partes bombas de miedo, odio, confusión y desinformación; sosteniendo una batalla en la que el reto era librar la confusión y salir ilesos del odio racial, del rechazo clasista, de la ruina económica y de la muerte. `}<strong parentName="p">{`Una guerra que desde el pequeño balcón de mi casa me hacía mirar al cielo como exigiendo explicaciones a unos dioses burlones del por qué los modelos económicos parecían no funcionar en una humanidad`}</strong>{` que perdía a quemarropa una libertad enmascarada en su capacidad económica. Una guerra en la que podía verse con nitidez que los verdaderos héroes de la batalla eran los médicos y el personal de salud, que parecían ser los únicos que enfrentaban el caos en el campo de un sistema sanitario totalmente devastado. Una guerra que nadie merecía.`}</p>
    <p>{`En medio de la paranoia mundial, la locura del encierro parecía revelarse también en el santo seno del hogar, en medio de un calor sofocante por las altas temperaturas de mayo, las batallas campales en la familia surgían como en un revelado fotográfico: la intolerancia, la incomprensión, el egoísmo y otros tantos defectos de carácter brotaban a cada instante como maleza estéril entre los que vivíamos bajo el mismo techo; pero también, lentamente, en medio de un canto desafinado de tolerancia y perdón, vimos detenerse el tiempo mientras palpábamos el amor a nuestro alrededor.`}</p>
    <p>{`Finalizando la cuarentena y anunciada la inminente ochentena, enfundada en su ropita ya encogida, con los pies descalzos y el pelo revuelto, Sofía, mi nieta de seis años, corre y salta por todos los rincones del grande y viejo departamento donde nos hemos atrincherado cinco adultos y ella; sin conciencia de su prisión, canta y ríe por todos lados y sus risas sacuden mi corazón que siente transmutar la zozobra en esperanza y en coraje por vivir, y observo que, como yerba en un rincón donde se acumula un poco de tierra frente al sol: el amor germina por todos lados, en la nueva relación con mis hijos, en los actos de generosidad y bondad de mis hermanos y amigos que no han dejado de apoyarnos desde el inicio de esta batalla que, como maremoto, va arrasando lo viejo, trayendo al mismo tiempo nuevos escenarios en los que aún hay caos y desasosiego. Y voy viendo con gran claridad que ni la vida ni la muerte serán ya iguales para nosotros, porque nunca más volveremos a ser los mismos que fuimos antes de la pandemia del COVID-19.`}</p>
    <p>{`La ciudad se cubre de polvo y olvido, las escuelas están abandonadas, algunos negocios ya no abrirán más, en la misma ciudad hay edificaciones reconstruyéndose, dentro de muy poco la ciudad tendrá un nuevo rostro. Hoy es el día más largo del año, es el solsticio de verano; en medio de las nacientes relaciones que emergen a través de plataformas digitales y que se han convertido en un hit, escapamos de la melancolía y el abatimiento. Continuamos cautivos en casa, el bicho no discrimina y continúa al acecho…, yo me atrevo a exclamar con valor y coraje que hemos librado apenas una batalla, pero que, a diferencia de otras guerras, hoy la muerte no llega sólo por azar.`}</p>
    <p>{`Junio de 2020, primer año de la pandemia.`}</p>
    <p>{`FIN`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      